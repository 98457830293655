import {SET_PROFILE, actionType, GET_OTHER_USER_DETAILS, GET_PERMISSIONS} from './types';
import * as types from "../file-browser/types";

export const setProfileAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: SET_PROFILE, payload })
}
export const getOtherUserDetails = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: GET_OTHER_USER_DETAILS,  payload, resolve, reject });
  })
}

export const getPermissions = (payload: any) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: GET_PERMISSIONS,  payload, resolve, reject });
  })
}

export const updateDomain = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CALL_UPDATE_DOMAIN,  payload, resolve, reject });
  })
}

export const updateProfile = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CALL_UPDATE_PROFILE,  payload, resolve, reject });
  })
}
