import { message } from "antd";
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callAuthServiceApi, callUstApi } from "../../helper/api.helper";
import {
  CALL_UPDATE_PROFILE, GET_OTHER_USER_DETAILS,
  GET_PERMISSIONS, LOGIN_REQUEST, SET_PROFILE, SET_USER_PERMISSIONS, UPDATE_ON_BOARDING, UPDATE_USER_ATTRIBUTES
} from './types';


export function* loginRequest() {
  yield takeEvery(LOGIN_REQUEST, function* (): SagaIterator {
    try {
      const response = yield call(login);
      const accessToken: string = response.accessToken;
      const profile: Object = response.user;
      yield put({
        type: SET_PROFILE,
        payload: {
          accessToken,
          profile
        }
      });
    } catch (error) {
    }
  });
}
export function* updateUserAttributes(){
  yield takeEvery(UPDATE_USER_ATTRIBUTES, function* ({ payload }: any): SagaIterator {
    try {
      const response = yield call(updateUserAttributesAPi, payload);
      yield put({
        type: UPDATE_ON_BOARDING,
        payload: response.length > 0 ? response[0] : null
      });
    } catch (error) {
      console.log({ error });

    }
  });
}
export function* getOtherUserDetailsSaga(){
  yield takeEvery(GET_OTHER_USER_DETAILS, function* (payload: any): SagaIterator {
    try {
      const response = yield call(getOtherUserDetailsApi, payload.payload);
      console.log({response})
      if(response){
        payload.resolve(response)
      }else {
        payload.reject()
      }
    } catch (error) {
      console.log({ error });
      payload.reject()

    }
  });
}
export function* getPermissionsSaga() {
  yield takeEvery(GET_PERMISSIONS, function* (payload: any): SagaIterator {
    try {
      const response = yield call(getPermissionsApi, payload.payload);
      console.log({ response });
      if(!response) return
      const permissions = response && response.length > 0 ? response : null
      if (!permissions) return
      const filteredPermissions = convertArrayToObject(response)
      yield put({ type: SET_USER_PERMISSIONS, payload: filteredPermissions});
      payload.resolve(response)
    } catch (error) {
      console.log({ error });
      payload.reject()
    }
  });
}

export function* updateProfileSaga() {
  yield takeEvery(CALL_UPDATE_PROFILE, function* ({ payload, resolve, reject }: any): SagaIterator {
    try {
      const response: any = yield call(updateProfile, payload);
      console.log({ response });
      if(!response) return
      // yield put({ type: types.UPDATE_WORKSPACE, payload: response })
      payload.resolve(response)
      message.destroy();
    } catch (error) {
      console.log({ error });
      message.destroy();
      payload.resolve()
    }
  });
}

function login(): any {
  const info: Object = {
    user: {
      name: 'umair',
      email: "umair.riaz@exponentoc.com",
      password: "123456",
    },
    accessToken: "q11cr1234"
  }
  return info;
}
async function updateUserAttributesAPi(payload:any) {
  return await callUstApi({ url: '/updateUserAttributes', payload, method: 'POST' })
}
async function getOtherUserDetailsApi(payload:any) {
  return await callUstApi({ url: '/get-user', payload, method: 'POST' })
}
async function getPermissionsApi(payload: any) {
  return await callAuthServiceApi({ url: '/user/getUserPermissions/' + payload.userId, payload: null, method: 'GET' })
}
function convertArrayToObject(array: any) {
  const mapped = array.map((item: any) => ({ [item.actionId]: item.allowed }) );
  return  Object.assign({}, ...mapped );
}

async function updateProfile(payload: any) {
  return await callUstApi({ url: '/update-profile', payload, method: 'POST' })
}


export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(updateUserAttributes),
    fork(getOtherUserDetailsSaga),
    fork(getPermissionsSaga),
    fork(updateProfileSaga)
  ]);
}
