import * as types from './types';

export const fetchAllProAndDir = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_ALL_PRO_AND_DIR, payload });
}
export const fetchWorkspace = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.FETCH_WORKSPACE_ONLY,  payload, resolve, reject });
  })
}

export const fetchWorkspaceAssociations = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.FETCH_WORKSPACE_ASSOCIATION,  payload, resolve, reject });
  })
}
export const createOnBoarding = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CREATE_ONBOARDING,  payload, resolve, reject });
  })
}
export const updateWorkspace = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CALL_UPDATE_WORKSPACE,  payload, resolve, reject });
  })
}
export const updateDomain = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.CALL_UPDATE_DOMAIN,  payload, resolve, reject });
  })
}

export const createNewDirAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.CREATE_NEW_DIR, payload });
}

export const updateDirAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_DIR, payload });
}

export const deleteDirAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.DELETE_DIR, payload });
}

export const updateProjectAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.UPDATE_PROJECT, payload, resolve,  reject});
  })
}

export const deleteProjectAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_PROJECT_VUMU_SERVERLESS, payload: {
      _id: payload._id,
      isDeleted: true
  }});

  dispatch({ type: types.DELETE_PROJECT_SUCCESS, payload });
}

export const updateFolderChain = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_FOLDER_CHAIN, payload });
}

export const updateCurrentDirectoryAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_CURRENT_DIRECTORY, payload });
}

export const addProjectAction = (payload: any) => (dispatch: any) => {
  console.log({ payload });
  dispatch({ type: types.ADD_PROJECT, payload })
}
export const addVumuProjectUpload = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({ type: types.ADD_VUMU_PROJECT_UPLOAD, payload, resolve, reject })
  })
}

export const setIframeVisibleAction = (payload: any) => (dispatch: any) => {
  console.log({ payload });
  dispatch({ type: types.SHOW_IFRAME, payload })
}

export const fileBrowserLoadingAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FB_LOADING, payload })
}

export const renderVideo = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.RENDER_VIDEO, payload });
}

export const addProjectKeeviAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.ADD_PROJECT_KEEVI, payload })
}

export const addProjectVumuAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
          type: types.ADD_PROJECT_VUMU,
          payload,
          resolve,
          reject
      })
  })
}

export const addProjectBlogVumuAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
      type: types.ADD_PROJECT_BLOG_VUMU,
      payload,
      resolve,
      reject
    })
  })
}

export const fetchProjectBlogVumuAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
      type: types.FETCH_PROJECT_BLOG_VUMU,
      payload,
      resolve,
      reject
    })
  })
}

export const getProjectBlogVumuAction = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
      type: types.FETCH_PROJECT_BLOG_VUMU,
      payload,
      resolve,
      reject
    })
  })
}

export const updateVumuProjectAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.UPDATE_PROJECT_VUMU, payload });
}

export const fetchTemplates = (payload: any) => (dispatch: any) =>  {
  dispatch({type: types.FETCH_TEMPLATES, payload});
}

export const fetchCategoriesList = (payload: any) => (dispatch: any) => {
  dispatch({type: types.FETCH_CATEGORIES, payload});
}

export const setProjectsToEmptyAction = () => (dispatch: any) => {
  dispatch({ type: types.SET_PROJECTS_TO_EMPTY });
}

export const getStatisticsAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.GET_STATISTICS_REQUEST, payload })
}

export const setNextPageAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.SET_NEXT_PAGE , payload });
}


export const getAllDirAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_ALL_DIR_REQ , payload });
}


export const getAllProAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_ALL_PRO_REQ , payload });
}

export const getAllDirFromWorkspaceAction = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_ALL_DIR_WRK_REQ , payload });
}

export const getCommentsCounts = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
          type: types.GET_COMMENTS_COUNT_REQ,
          payload,
          resolve,
          reject
      })
  })
}

export const fetchWorkspaceForBUser = (payload: any) => (dispatch: any) => {
  return new Promise<any>((resolve, reject) => {
    dispatch({
          type: types.GET_USER_B_WORKSPACE,
          payload,
          resolve,
          reject
      })
  })
}
