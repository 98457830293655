import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../redux/store';
import './style.scss';
import {message, Input, Button, Steps, Select, InputNumber, Modal} from 'antd';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { InlineWidget } from 'react-calendly';
import { CURRENCY_JSON, TIME_ZONE, COUNTRY_JSON } from '../../utils/constants';
import * as types from '../../redux/file-browser/types';
import * as AUTH_TYPES from '../../redux/auth/types';
import {addVumuProjectUpload, createOnBoarding, updateDomain} from '../../redux/file-browser/actions';
import {showPlaybooksPopup, upgradeModalVisibiltyAction} from '../../redux/app/actions';
import PhoneInput from 'react-phone-input-2';
import { getSession } from '../../helper/ust.helper';

import 'react-phone-input-2/lib/style.css';
import config from "../../config";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {checkActionPermissionStatus} from "../../utils/permissions-helper";
import {CALL_UPDATE_PROFILE} from "../../redux/auth/types";
import {updateProfile} from "../../redux/auth/actions";

const { Option } = Select;

interface Iprops {
  children?: any;
  history?: any;
}
declare global {
  interface Window {
    $crisp: any;
  }
}

const { Step } = Steps;
const OnBoardComponent = (props: Iprops) => {
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(
    (state: RootState) => state.browserReducer.currentWorkspace,
  );
  const onBoardingSettings = useSelector(
    (state: RootState) => state.browserReducer.onBoardingSettings,
  );
  const currentUser = useSelector(
    (state: RootState) => state.authReducer.profile,
  );
  const [currentTab, setCurrentTab] = useState(0);
  const [loadingApi, setLoadingApi] = useState(false);
  const [weblinkMessage, setWeblinkMessage] = useState('');
  const [validationError, setValidationError] = useState(null);
  const [duplicateMsg, setDuplicateMsg] = useState(null);
  const [weblinkValue, setWeblinkValue] = useState("");
  const [domainLoading, setDomainLoading] = useState(false);
  const [country, setCountry] = useState({ code: 'us'});
  const objectId = () => {
    return uuidv4().replace(/-/g, '').substr(0, 24).toLowerCase();
  };
  const steps = [
    {
      title: 'Welcome',
      content: 'First-content',
    },
    {
      title: 'Company Details',
      content: 'Second-content',
    }
  ];

  const next = () => {
    setCurrentTab(currentTab + 1);
  };

  const prev = () => {
    setCurrentTab(currentTab - 1);
  };
  const handleOInputOnboarding = (value: any, type: any) => {
    setValidationError(null);
    switch (type) {
      case 'NAME':
        dispatch({
          type: types.ADD_ONBOARDING_SETTINGS,
          payload: { ...onBoardingSettings, name: value },
        });
        break;
      case 'COMPANY_NAME':
        dispatch({
          type: types.ADD_ONBOARDING_SETTINGS,
          payload: { ...onBoardingSettings, companyName: value },
        });
        break;
      case 'WEBLINK':
        const state = store.getState();
        dispatch({
          type: types.ADD_ONBOARDING_SETTINGS,
          payload: { ...state.browserReducer.onBoardingSettings, weblink: value },
        });
        break;
      case 'COUNTRY':
        dispatch({
          type: types.ADD_ONBOARDING_SETTINGS,
          payload: { ...onBoardingSettings, countryName: value },
        });
        break;
      case 'PHONE_NUMBER':
        dispatch({
          type: types.ADD_ONBOARDING_SETTINGS,
          payload: { ...onBoardingSettings, phoneNumber: value },
        });
        break;
      case 'CURRENCY':
        dispatch({
          type: types.ADD_ONBOARDING_SETTINGS,
          payload: { ...onBoardingSettings, currency: value },
        });
        break;
      case 'TIME':
        dispatch({
          type: types.ADD_ONBOARDING_SETTINGS,
          payload: { ...onBoardingSettings, timeZone: value },
        });
        break;
      default:
        return;
    }
  };
  const verifyDetails = async () => {
    if (
      onBoardingSettings &&
      // onBoardingSettings.timeZone &&
      // onBoardingSettings.currency &&
      // onBoardingSettings.countryName &&
      // onBoardingSettings.companyName &&
      // onBoardingSettings.companyName.trim().length &&
      onBoardingSettings.name &&
      onBoardingSettings.name.trim().length &&
      // onBoardingSettings.phoneNumber &&
      // onBoardingSettings.phoneNumber.trim().length &&
      onBoardingSettings.weblink &&
      onBoardingSettings.weblink.trim().length
    ) {
      try{
        setLoadingApi(true);
        const onBoardingPayload = {
          ...onBoardingSettings,
          userId: currentUser?.userId,
          workspaceId: currentWorkspace?._id,
          onBoard: true,
          countryName: (country && country.country) ? country.country : 'n/a',
          companyName: 'n/a'
        };

        onBoardingPayload.workspaceFavIcon = config.workspaceFavIcon;
        onBoardingPayload.workspaceLogo = config.workspaceLogo;
        delete onBoardingPayload.weblink;

        // dispatch({
        //   type: types.CALL_UPDATE_WORKSPACE,
        //   payload: {...currentWorkspace, workspaceDomain: onBoardingSettings.weblink }
        // });

        await updateProfileData('name', onBoardingSettings.name);

        getSession().then(async ({ user }) => {
          onBoardingPayload.email = user.username;

          await dispatch({
            type: types.CALL_UPDATE_PROFILE,
            payload: {
              userId: currentWorkspace?.userId,
              activeEmail: user.username,
              phone: {countryCode: country.calling_code, phoneNumber: onBoardingSettings.phoneNumber}
            }
          });

          dispatch(addVumuProjectUpload({
            _id: objectId(),
            videoUrl: 'https://d3ta3go6wr2nns.cloudfront.net/081a9b8b-5a83-44f2-9894-61d628f2317c/mp4/6396ff2290185ce6c3b9a127_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4',
            thumbnailUrl: 'https://cdn.getmeetingless.com/assets/getting-started-meetingless.jpg',
            createdBy: currentWorkspace?.userId,
            workspace: currentWorkspace._id,
            title: 'Onboarding to Meetingless',
            screenshots: []
          }));

          dispatch(createOnBoarding({ ...onBoardingPayload }))
          creatOnboardingApi()

          setTimeout(() => {
            window.location.reload();
          }, 13000);
        })
      } catch (e) {
        console.log('error in onboard API')
      }
      try{
        let crisp: any = window.$crisp;
        crisp.push(['set', 'user:name', [onBoardingSettings.name]])

        if(onBoardingSettings.phoneNumber){
          crisp.push(['set', 'user:phone', [onBoardingSettings.phoneNumber]])
          crisp.push(["set", "session:segments", [["NumberProvided"]]]);
        }
        // crisp.push([
        //   'set',
        //   'user:company',
        //   [
        //     onBoardingSettings.companyName,
        //     { geolocation: [onBoardingSettings.countryName, ''] },
        //   ],
        // ]);
        // crisp.push([
        //   'set',
        //   'session:data',
        //   ['currency', onBoardingSettings.currency],
        // ]);
        // crisp.push([
        //   'set',
        //   'session:data',
        //   ['timezone', onBoardingSettings.timeZone],
        // ]);
      }catch (e){
        console.log(e)
      }
    } else {
      // @ts-ignore
      setValidationError('Please Add All the required Information');
    }
  };

  const updateProfileData = (attribute, value) => {
    return getSession().then(({ user }) => {
      const attributes = [
        new CognitoUserAttribute({ Name: attribute, Value: value }),
      ];

      user.updateAttributes(attributes, (err, result) => {
        if (err) console.error(err);
      });
    });
  }

  const creatOnboardingApi = async () => {
    dispatch({
      type: types.CALL_UPDATE_WORKSPACE,
      payload: { ...currentWorkspace, onBoard: true },
    });

    // setTimeout(() => {
    //   dispatch(showPlaybooksPopup(true));
    // }, 3000);

    // window.$crisp.push(["do", "chat:open"])
  };

  const handleWeblinkInputDebounce = useCallback(
    _.debounce((value) => {
      console.log({ value });
      const _action = "canCreateSubdomain";
      const isPermitted = checkActionPermissionStatus(_action);
      // if (!isPermitted) {
      //   dispatch(upgradeModalVisibiltyAction(true));
      //   dispatch({
      //     type: types.UPDATE_WORKSPACE,
      //     payload: {...currentWorkspace, workspaceDomain: ''}
      //   });
      // }else {
        value = value.toLowerCase()
        if(value !== '') {
          setDomainLoading(true)
          dispatch({
            type: types.UPDATE_WORKSPACE,
            payload: {...currentWorkspace, workspaceDomain: value}
          });
          dispatch(updateDomain({_id:currentWorkspace?._id, userId: currentWorkspace?.userId, workspaceDomain: value })).then((res:any)=> {
            if(res &&  res.duplicate === true) {
              setDuplicateMsg('This sub-domain is not available please try a different one')
              setDomainLoading(false)
              dispatch({
                type: types.UPDATE_WORKSPACE,
                payload: {...currentWorkspace, workspaceDomain:null}
              });
              return
            }
            setDomainLoading(false)
            setDuplicateMsg(null)
            setWeblinkMessage("This weblink is available!")

            handleOInputOnboarding(value, 'WEBLINK');
            // message.success(`Domain updated`);
          })
        }
      // }
    }, 1100, {trailing: true}),
    []
  );

  const handleWeblinkInput = (value) => {
    const jwtToken = currentUser?.jwtToken;
    handleWeblinkInputDebounce(value, jwtToken);
    setWeblinkValue(value);
  }

  useEffect(() => {
    console.log({ onBoardingSettings });
  }, [onBoardingSettings]);

    useEffect(() => {
        // let crisp: any = window.$crisp;
        // crisp.push(['do', 'chat:hide']);

      fetch('https://api.ipregistry.co/?key=tryout')
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          if(response && response.location && response.location.country)
          setCountry(response.location.country);
        });
    }, [])

  // @ts-ignore
  return (
    <>
      <div className="dashboard-body">
        <div className="stepsBg" style={{ backgroundImage: `url(${config.DESTINATION_MEDIA_CDN+ "assets/vumuBodyBG.png"})`}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="stepsLogo">
                  <img src={config.DESTINATION_MEDIA_CDN + "assets/icons_skining/meetingless_logo_full.png"} style={{ width: '170px'}} />
                </div>
                <div className="step-container">
                  <div className="stepsHeader">
                    <div className="headerContent">
                      <Steps current={currentTab}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                      </Steps>
                    </div>
                  </div>
                  <div className="stepsContent">
                    {currentTab === 0 ? (
                        <div className="welcomeTab">
                          <img src={config.DESTINATION_MEDIA_CDN + "assets/icons_skining/onBoardingLogo.png"} />
                          <h2>Welcome to Meetingless</h2>
                          <p>
                            Let’s get you set up so you can start personalised outreach quickly.
                          </p>
                          <span>
                        We need some essential information. It will take less than 30 seconds.
                      </span>
                        </div>
                    ) : currentTab === 1 ? (
                        <>
                          <div className="secondTab">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-wrapper">
                                    <label className="label-sm">Your Name* </label>
                                    <Input
                                        placeholder="Type your name"
                                        value={
                                          onBoardingSettings && onBoardingSettings.name
                                        }
                                        onChange={e => {
                                          handleOInputOnboarding(
                                              e.target.value,
                                              'NAME',
                                          );
                                        }}
                                        style={
                                          validationError && (!onBoardingSettings.name || !onBoardingSettings.name.trim().length)
                                              ? { border: '1px solid red' }
                                              : {}
                                        }
                                    />
                                  </div>
                                </div>
                                {/*<div className="col-md-6">*/}
                                {/*  <div className="form-wrapper">*/}
                                {/*    <label className="label-sm">*/}
                                {/*      Your Company Name*{' '}*/}
                                {/*    </label>*/}
                                {/*    <Input*/}
                                {/*        placeholder="Company Name"*/}
                                {/*        value={*/}
                                {/*          onBoardingSettings &&*/}
                                {/*          onBoardingSettings.companyName*/}
                                {/*        }*/}
                                {/*        onChange={e => {*/}
                                {/*          handleOInputOnboarding(*/}
                                {/*              e.target.value,*/}
                                {/*              'COMPANY_NAME',*/}
                                {/*          );*/}
                                {/*        }}*/}
                                {/*        style={*/}
                                {/*          validationError &&*/}
                                {/*          (!onBoardingSettings.companyName || !onBoardingSettings.companyName.trim().length)*/}
                                {/*              ? { border: '1px solid red' }*/}
                                {/*              : {}*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*  </div>*/}
                                {/*</div>*/}
                              </div>
                              <div className="row">
                                {/*<div className="col-md-6">*/}
                                {/*  <div className="form-wrapper">*/}
                                {/*    <label className="label-sm" htmlFor="">*/}
                                {/*      Country**/}
                                {/*    </label>*/}
                                {/*    <Select*/}
                                {/*        defaultValue={*/}
                                {/*          onBoardingSettings &&*/}
                                {/*          onBoardingSettings.countryName*/}
                                {/*        }*/}
                                {/*        placeholder={'Select your Country'}*/}
                                {/*        onChange={e => {*/}
                                {/*          handleOInputOnboarding(e, 'COUNTRY');*/}
                                {/*        }}*/}
                                {/*        style={*/}
                                {/*          validationError &&*/}
                                {/*          !onBoardingSettings.countryName*/}
                                {/*              ? { outline: '1px solid red' }*/}
                                {/*              : {}*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*      {COUNTRY_JSON.map(elem => (*/}
                                {/*          <Option key={elem.code}>{elem.name}</Option>*/}
                                {/*      ))}*/}
                                {/*    </Select>*/}
                                {/*  </div>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-6">*/}
                                {/*  <div className="form-wrapper">*/}
                                {/*    <label className="label-lg" htmlFor="">*/}
                                {/*      Currency**/}
                                {/*    </label>*/}
                                {/*    <Select*/}
                                {/*        defaultValue={*/}
                                {/*          onBoardingSettings &&*/}
                                {/*          onBoardingSettings.currency*/}
                                {/*              ? onBoardingSettings.currency*/}
                                {/*              : 'USD'*/}
                                {/*        }*/}
                                {/*        placeholder={'Select your Currency'}*/}
                                {/*        onChange={e => {*/}
                                {/*          handleOInputOnboarding(e, 'CURRENCY');*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*      {CURRENCY_JSON.map(elem => (*/}
                                {/*          <Option key={elem.cc}>{elem.name}</Option>*/}
                                {/*      ))}*/}
                                {/*    </Select>*/}
                                {/*  </div>*/}
                                {/*</div>*/}
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-wrapper">
                                    <label className="label-sm" htmlFor="">Phone Number</label>
                                    <PhoneInput
                                        country={(country && country.code) ? country.code.toLowerCase() : 'us'}
                                        onChange={e => {
                                          handleOInputOnboarding(e, 'PHONE_NUMBER');
                                        }}
                                        inputClass="phone-input"
                                        buttonClass="flag-button"
                                    />
                                  </div>
                                </div>
                                {/*<div className="col-md-6">*/}
                                  {/*<div className="form-wrapper">*/}
                                  {/*  <label className="label-lg" htmlFor="">*/}
                                  {/*    Time Zone**/}
                                  {/*  </label>*/}
                                  {/*  <Select*/}
                                  {/*      defaultValue={*/}
                                  {/*        onBoardingSettings &&*/}
                                  {/*        onBoardingSettings.timeZone*/}
                                  {/*            ? onBoardingSettings.timeZone*/}
                                  {/*            : 'UTC'*/}
                                  {/*      }*/}
                                  {/*      placeholder={'Select Your Timezone'}*/}
                                  {/*      onChange={e => {*/}
                                  {/*        handleOInputOnboarding(e, 'TIME');*/}
                                  {/*      }}*/}
                                  {/*  >*/}
                                  {/*    {TIME_ZONE.map(elem => (*/}
                                  {/*        <Option key={elem.value}>{elem.text}</Option>*/}
                                  {/*    ))}*/}
                                  {/*  </Select>*/}
                                  {/*</div>*/}
                                {/*</div>*/}
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="formNote">
                                    <p>
                                      Please ensure you enter correct number. We will be sharing free bonuses via SMS.
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className="col-md-6">
                                  <div className="form-wrapper">
                                    <label className="label-sm">Create your own Weblink*</label>
                                    <div className='weblink' style={
                                      duplicateMsg
                                        ? { border: '1px solid red' }
                                        : {}
                                    }>
                                      <Input
                                        placeholder="Type your weblink"
                                        value={weblinkValue}
                                        onChange={e => {
                                          setWeblinkValue(e.target.value);
                                          handleWeblinkInput(e.target.value)}
                                        }
                                        style={
                                          validationError && (!onBoardingSettings.weblink || !onBoardingSettings.weblink.trim().length)
                                            ? { border: '1px solid red' }
                                            : {}
                                        }
                                      />

                                      <label className='weblink-extend'>
                                        {config.APP_HOST}
                                        {domainLoading && <span style={{ paddingLeft: '6px' }} className="domain-spinner"><LoadingOutlined/></span>}
                                      </label>

                                    </div>
                                    <span className="sub-domain-footer-msg">Please add your weblink so that the video landing page url will be shared on weblink.</span>
                                    {duplicateMsg && <div style={{marginTop: '7px'}}>
                                      <span className="sub-domain-footer">{duplicateMsg}</span>
                                    </div>}
                                    {weblinkMessage && <div style={{marginTop: '7px'}}>
                                      <span className="sub-domain-footer-success">{weblinkMessage}</span>
                                    </div>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                    ) : currentTab === 2 ? (
                        <div className="dateSelection" style={{"marginTop": "-66px"}}>
                          <InlineWidget url="https://calendly.com/vumu-accounts-management/vumu-onboarding-call" />
                        </div>
                    ) : null}
                  </div>
                  <div className="stepsFooter">
                    <div className="steps-action">
                      {currentTab === 0 && (
                          <Button className="btnAlpha" onClick={() => next()}>
                            Next: Company Details
                          </Button>
                      )}
                      {currentTab === 1 && (
                          <Button
                              className="btnAlpha"
                              loading={loadingApi}
                              onClick={() => verifyDetails()}
                              disabled={!onBoardingSettings.weblink || !onBoardingSettings.name}
                          >
                            Next: Dashboard
                          </Button>
                      )}
                      {/*{currentTab === 2 && (*/}
                      {/*    <Button*/}
                      {/*        className="btnAlpha"*/}
                      {/*        onClick={() => creatOnboardingApi()}*/}
                      {/*    >*/}
                      {/*      Start Using The Application*/}
                      {/*    </Button>*/}
                      {/*)}*/}
                      {/*{currentTab > 0 && (*/}
                      {/*    <Button className="btnBeta" style={{margin: '0 8px'}}*/}
                      {/*            onClick={() => prev()}>*/}
                      {/*        Previous*/}
                      {/*    </Button>*/}
                      {/*)}*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnBoardComponent;
