console.log({env: process.env.REACT_APP_ENV})
let config : any = {
    RENDER_API_URL: process.env.REACT_APP_RENDER_API_URL,
    DASHBOARD_SERVERLESS_API: process.env.REACT_APP_DASHBOARD_SERVERLESS_API,
    APP_HOST: process.env.REACT_APP_APP_HOST,
    appUrl: process.env.REACT_APP_appUrl,
    redirect: process.env.REACT_APP_redirect,
    iFrameUrl: process.env.REACT_APP_iFrameUrl,
    addContentCTA: process.env.REACT_APP_addContentCTA,
    editInStudioCTA: process.env.REACT_APP_editInStudioCTA,
    getSignedUrl: process.env.REACT_APP_getSignedUrl,
    ustCookieDomain: process.env.REACT_APP_ustCookieDomain,
    ustPoolId: process.env.REACT_APP_ustPoolId,
    ustClientId: process.env.REACT_APP_ustClientId,
    ustRedirectLink: process.env.REACT_APP_ustRedirectLink,
    ustRedirectAccount: process.env.REACT_APP_ustRedirectAccount,
    playbooksUrl: process.env.REACT_APP_playbooksUrl,
    previewLink: process.env.REACT_APP_previewLink,
    studioServer: process.env.REACT_APP_studioServer,
    ACCESS_KEY_ID: process.env.REACT_APP_ACCESS_KEY_ID,
    SECRETE_ACCESS_KEY: process.env.REACT_APP_SECRETE_ACCESS_KEY,
    BUCKET_S3_DESTINATION: process.env.REACT_APP_BUCKET_S3_DESTINATION,
    REGION: process.env.REACT_APP_REGION,
    DESTINATION_MEDIA_CDN: process.env.REACT_APP_DESTINATION_MEDIA_CDN,
    VUMU_API_GATEWAY: process.env.REACT_APP_VUMU_API_GATEWAY,
    UST_API_GATEWAY: process.env.REACT_APP_UST_API_GATEWAY,
    AUTH_SERVICE_API: process.env.REACT_APP_AUTH_SERVICE_API,
    DASHBOARD_SERVICE_API: process.env.REACT_APP_DASHBOARD_SERVICE_API,

    RECORDING_API                   :process.env.REACT_APP_RECORDING_API,
    RECORDING_BUCKET_SOURCE         :process.env.REACT_APP_RECORDING_BUCKET_SOURCE,
    RECORDING_BUCKET_DESTINATION    :process.env.REACT_APP_RECORDING_BUCKET_DESTINATION,
    RECORDING_CDN_URL               :process.env.REACT_APP_RECORDING_CDN_URL,
    RECORDING_STREAM_URL            :process.env.REACT_APP_RECORDING_STREAM_URL,
    REDIRECTION_UPON_COMPLETION_URL :process.env.REACT_APP_REDIRECTION_UPON_COMPLETION_URL,

    CRISP_WEBSITE_ID: process.env.REACT_APP_CRISP_WEBSITE_ID,
    EXTENSION_URL: process.env.REACT_APP_EXTENSION_URL,
    GA_WEBSITE_ID: process.env.REACT_APP_GA_WEBSITE_ID,
    TC_JS_PATH: process.env.REACT_APP_TC_JS_PATH,
    PRODUCTS: process.env.REACT_APP_PRODUCTS,
    B_TOOLS: process.env.REACT_APP_B_TOOLS,
    DEFAULT_SETTING_LOGO: process.env.REACT_APP_DEFAULT_SETTING_LOGO,
    DEFAULT_SETTING_FAVICON: process.env.REACT_APP_DEFAULT_SETTING_FAVICON,
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
    EXTERNAL_PURCHASE_LINK: process.env.REACT_APP_EXTERNAL_PURCHASE_LINK,
    PURCHASE_LINK: process.env.REACT_APP_PURCHASE_LINK,
    AMPLITUDE_SECRETE_KEY: process.env.REACT_APP_AMPLITUDE_SECRETE_KEY,
    ROADMAP_LINK: process.env.REACT_APP_ROADMAP_LINK,
}

const UPLOADED_VIDEOS_FOLDER =  "uploaded-videos";
const THUMBNAILS_FOLDER =  "custom-thumbnails";
const CUSTOM_SETTINGS_FOLDER =  "custom-settings";
const APP = process.env.REACT_APP_title;
const APP_LOGO = process.env.REACT_APP_logoUrl;
const APP_FAVICON = process.env.REACT_APP_favIcon;

export default {
    ...config,
    UPLOADED_VIDEOS_FOLDER,
    THUMBNAILS_FOLDER,
    CUSTOM_SETTINGS_FOLDER,
    APP,
    APP_LOGO,
    APP_FAVICON
}

export type apiType = any;
